<template>
  <idle-display :duration="60 * idle_timeout">
    <div
      id="app"
      class="h-100"
      :class="[skinClasses]"
    >
      <component :is="layout" v-cloak>
        <router-view :key="$route.path" />
      </component>

      <scroll-to-top v-if="enableScrollToTop" />

      <div id="sound-source" style="display: none"></div>
    </div>
  </idle-display>
</template>

<script>
import ScrollToTop from '@core/components/scroll-to-top/ScrollToTop.vue'

// This will be populated in `beforeCreate` hook
import { $themeBreakpoints, $themeColors, $themeConfig } from '@themeConfig'
import { provideToast } from 'vue-toastification/composition'
import { watch } from '@vue/composition-api'
import { useCssVar, useWindowSize } from '@vueuse/core'
import { get } from "lodash"

import useAppConfig from '@core/app-config/useAppConfig'
import store from '@/store'

import IdleDisplay from "@core/components/shared/idle/IdleDisplay.vue"

import { MUTATE_ONLINE_USERS } from "@/store/config/mutation-keys"

const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

export default {
  components: {
    IdleDisplay,

    // Layouts
    LayoutHorizontal,
    LayoutVertical,
    LayoutFull,

    ScrollToTop,
  },
  setup() {
    const { skin, skinClasses } = useAppConfig()
    const { enableScrollToTop } = $themeConfig.layout

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Provide toast for Composition API usage
    // This for those apps/components which uses composition API
    provideToast({
      hideProgressBar: true,
      closeOnClick: false,
      closeButton: false,
      icon: false,
      timeout: 3000,
      transition: 'Vue-Toastification__fade',
    })

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
      enableScrollToTop,
    }
  },
  data(){
    return {
      loading: false,
      loaded: false
    }
  },
  computed: {
    layout() {
      if (this.$route.meta.layout === 'full') return 'layout-full'
      return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
    settings() {
      return this.$store.getters[`auth/settings`];
    },
    idle_timeout() {
      return get(this.settings, 'security_settings.idle_timeout', 5)
    }
  },
  watch: {
    isLoggedIn: {
      handler(){
        this.$nextTick(() => {
          if (this.loaded){
            this.setChatWootUser();
          }
        });
      },
      immediate: false
    },
    "$socket.disconnected": {
      handler(disconnected){
        if (disconnected && this.isLoggedIn){
          this.retrySocketConnectionWithExponentialBackoff();
        }
      }, 
      deep: true,
      immediate: true
    },
    "$socket.connected": {
      handler(){
        // connected && console.log("[SOCKET CONNECTED]")
      }, 
      deep: true,
      immediate: true
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }


    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  destroyed() {
    this.$socket.client.close()
  },
  async created(){
    this.loaded = true;
    this.loadChatWootWidget();
  },
  methods: {
    updateOnlineUsers(onlineUsers){
      this.$store.commit(`auth/${MUTATE_ONLINE_USERS}`, onlineUsers);
    },
    setChatWootUser() {
      const phone = this.getValueFromSource(this.currentUser, 'phone').replace("0", "+233");

      window.$chatwoot.setUser(this.getValueFromSource(this.currentUser, 'ww_hash'), {
        email: this.getValueFromSource(this.currentUser, 'email'),
        name: this.getValueFromSource(this.currentUser, 'full_name'),
        avatar_url: this.getValueFromSource(this.currentUser, 'avatar.path'),
        phone_number: phone,
      });
    },
    loadChatWootWidget() {
      const chatwootBaseUrl = process.env.VUE_APP_BACKOFFICE_BASEURL ?? 'https://backoffice.exxtra.app';
      const chatwootWebsiteToken = process.env.VUE_APP_BACKOFFICE_WEBSITE_TOKEN ?? 'LTGbNk1TzsH2kJJ9LJQPHC7E';


      const script1 = document.createElement("script")
      const script0 = document.getElementsByTagName("script")[0];

      script1.src = `${chatwootBaseUrl}/packs/js/sdk.js`;
      script1.defer = true;
      script1.async = true;
      script0.parentNode.insertBefore(script1, script0);

      script1.onload = () => window.chatwootSDK.run({
        websiteToken: chatwootWebsiteToken,
        baseUrl: chatwootBaseUrl
      });

      window.chatwootSettings = {
        type: "expanded_bubble",
        position: "left",
        launcherTitle: "Say Hi",
        darkMode: "light"
      };
    }
  },
}
</script>

<style scoped>
  [v-cloak] {
    display: none;
  }
</style>
