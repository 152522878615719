export default class SharedJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  /** Client Groups */
  fetchClientGroups() {
    return this.axiosIns.get(this.jwtConfig.shared.fetchClientGroupsEndpoint, {})
  }

  fetchLoanSettings() {
    return this.axiosIns.get(this.jwtConfig.shared.sharedLoanSettings, {})
  }

  fetchBlogPosts(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}`, {
      params
    })
  }

  /** TESTIMONIALS */
  fetchTestimonials(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedTestimonialsEndpoint}`, {
      params
    })
  }

  /** faqs */
  fetchFaqs(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedFaqsEndpoint}`, {
      params
    })
  }


  /** kickstart registrations */
  registerStarter(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.shared.sharedStartersEndpoint}`, payload);
  }

  registerGraduateApplicant(payload = {}) {
    return this.axiosIns.post(`${this.jwtConfig.shared.sharedGraduateEndpoint}`, payload);
  }

  updateGraduateApplicant(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.shared.sharedGraduateEndpoint}/${id}`, payload);
  }

  updateGraduateApplicantContact(id, payload = {}) {
    return this.axiosIns.put(`${this.jwtConfig.shared.sharedGraduateEndpoint}/contact/${id}`, payload);
  }


  /** Blog POSTS */
  fetchBlogPost(slug) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}/${slug}`, {})
  }

  fetchRecentBlogPosts() {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedBlogsEndpoint}/query/recent`, {})
  }

  submitBlogComment(blog_id, params) {
    return this.axiosIns.post(`${this.jwtConfig.shared.sharedBlogsEndpoint}/${blog_id}/comment`, params)
  }

  /** Products */
  fetchProducts(params) {
    return this.axiosIns.get(this.jwtConfig.shared.sharedProductsEndpoint, {
      params
    })
  }

  fetchProduct(slug) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedProductsEndpoint}/${slug}`, {})
  }

  fetchProductCategories(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedProductsEndpoint}/options/categories`, {
      params
    });
  }

  fetchProductShops(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedProductsEndpoint}/options/shops`, {
      params
    });
  }

  fetchProductBrands(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedProductsEndpoint}/options/brands`, {
      params
    });
  }

  fetchStories(params) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedStoriesEndpoint}`, {
      params
    })
  }

  fetchStory(id) {
    return this.axiosIns.get(`${this.jwtConfig.shared.sharedStoriesEndpoint}/${id}`, {})
  }
}

