import Vue from 'vue'
import Vuex from 'vuex'

// Core Modules
import app from './modules/app'
import appConfig from './modules/app-config'
import verticalMenu from './modules/vertical-menu'
import ecommerceStoreModule from './modules/eCommerceStoreModule'

// App Modules
import auth from "./modules/auth"
import navigation from "./modules/navigation"

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    auth,
    appConfig,
    navigation,
    verticalMenu,
    'app-ecommerce': ecommerceStoreModule,
  },
  strict: process.env.DEV,
})
