export default class OmcJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchDashboardData() {
    return this.axiosIns.get(`${this.jwtConfig.omc.omcDashboardEndpoint}`, {})
  }

  fetchDashboardGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.omc.omcDashboardEndpoint}/graph`, {
      params
    })
  }

  fetchSettlements(params) {
    return this.axiosIns.get(`${this.jwtConfig.omc.omcSettlementEndpoint}`, {
      params
    })
  }

  exportSettlements(params) {
    return this.axiosIns.get(`${this.jwtConfig.omc.omcSettlementEndpoint}/export`, {
      params,
      responseType: "blob"
    })
  }

  fetchOmcAdministrators(params) {
    return this.axiosIns.get(this.jwtConfig.omc.omcAdminsEndpoint, {
      params
    })
  }

  fetchOmcAdministrator(omc_admin_id) {
    return this.axiosIns.get(`${this.jwtConfig.omc.omcAdminsEndpoint}/${omc_admin_id}`, {})
  }

  updateOmcAdministrator(omc_admin_id, payload) {
    return this.axiosIns.put(`${this.jwtConfig.omc.omcAdminsEndpoint}/${omc_admin_id}`, payload)
  }

  deleteOmcAdministrator(omc_admin_id) {
    return this.axiosIns.delete(`${this.jwtConfig.omc.omcAdminsEndpoint}/${omc_admin_id}`, {})
  }

  createOmcAdministrator(payload) {
    return this.axiosIns.post(this.jwtConfig.omc.omcAdminsEndpoint, payload)
  }

  exportOmcAdmins(params) {
    return this.axiosIns.get(`${this.jwtConfig.omc.omcAdminsEndpoint}/export`, {
      params,
      responseType: "blob"
    })
  }

  resendOmcAdminInvitation(payload) {
    return this.axiosIns.post(`${this.jwtConfig.omc.omcAdminsEndpoint}/resend-invitation`, payload)
  }

  fetchOmcBranches(params) {
    return this.axiosIns.get(this.jwtConfig.omc.omcBranchesEndpoint, {
      params
    })
  }
}

