export default class ClientJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  /** Client Dashboard */
  fetchDashboardData() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientDashboardEndpoint}`, {})
  }

  /** Client Loan */
  requestLoan(formData) {
    return this.axiosIns.post(this.jwtConfig.client.requestLoanEndpoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  fetchLoanRecords(params) {
    return this.axiosIns.get(this.jwtConfig.client.clientLoanEndpoint, {
      params
    })
  }

  fetchReferralCommissions(params) {
    return this.axiosIns.get(this.jwtConfig.client.clientReferralCommissionsEndpoint, {
      params
    })
  }

  fetchReferralCommissionWithdrawals(params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientReferralCommissionsEndpoint}/withdrawals`, {
      params
    })
  }

  fetchReferralCommissionWithdrawalStats(params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientReferralCommissionsEndpoint}/withdrawals/stats`, {
      params
    })
  }

  reportIssueWithReferralCommissionWithdrawals(withdrawalId, payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientReferralCommissionsEndpoint}/withdrawals/${withdrawalId}/issues`, payload)
  }

  cancelReferralCommissionWithdrawal(withdrawalId) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientReferralCommissionsEndpoint}/withdrawals/${withdrawalId}/cancel`)
  }

  withdrawReferralCommissions(payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientReferralCommissionsEndpoint}/withdraw`, payload)
  }

  fetchReferralStatistics() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientReferralCommissionsEndpoint}/stats`)
  }

  fetchReferralCommissionsAvailableBalance() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientReferralCommissionsEndpoint}/available/balance`)
  }

  fetchLoanRecord(loan_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanEndpoint}/${loan_id}`, {})
  }

  fetchLoanStatement(loan_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanStatement}/${loan_id}`, {})
  }

  fetchLoanProductsSummary() {
    return this.axiosIns.get(`${this.jwtConfig.client.clientDashboardEndpoint}/loan-products-summary`)
  }

  fetchPayOffLoanRecord(loan_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanEndpoint}/${loan_id}/pay-off`, {})
  }


  payOffLoan(loan_id, formData) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientLoanEndpoint}/${loan_id}/pay-off`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  cancelLoanRequest(loan_id) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientLoanEndpoint}/${loan_id}/cancel`, {})
  }

  submitClientGroupCustomFieldAnswers(client_group_id, payload) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientLoanClientGroupEndpoint}/${client_group_id}/custom-field-answers`, payload, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
  }

  canRequestLoanProduct(client_group_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanClientGroupEndpoint}/${client_group_id}/can-request-loan`)
  }

  loanProductOutputPercentage(client_group_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanClientGroupEndpoint}/${client_group_id}/output`)
  }

  isUserDataVerified(client_group_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanClientGroupEndpoint}/${client_group_id}/verified`)
  }

  fetchManualRepayments(params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanRepaymentEndpoint}/manual`, {
      params
    })
  }

  fetchManualRepaymentRecord(manual_repayment_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanRepaymentEndpoint}/${manual_repayment_id}/manual`, {})
  }

  cancelPayOffRequest(manual_repayment_id) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientLoanRepaymentEndpoint}/${manual_repayment_id}/manual/cancel`, {});
  }

  /** Admin Transactions */
  fetchTransactions(params) {
    return this.axiosIns.get(this.jwtConfig.client.clientTransactionsEndpoint, {
      params
    })
  }

  /** Admin Products */
  requestProduct(payLoad) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientProductsEndpoint}/request`, payLoad, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  }

  canRequestProduct(product_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientProductsEndpoint}/requests/${product_id}/can-request`, {});
  }

  fetchProductRequests(params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientProductsEndpoint}/requested`, {
      params
    })
  }

  fetchProductRequest(product_requested_id) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientProductsEndpoint}/requests/${product_requested_id}`, {});
  }

  cancelProductRequest(product_requested_id) {
    return this.axiosIns.post(`${this.jwtConfig.client.clientProductsEndpoint}/requests/${product_requested_id}/cancel`, {});
  }

  fetchLoanProducts(params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanProductsEndpoint}`, {
      params
    })
  }

  fetchLoanProduct(id, params) {
    return this.axiosIns.get(`${this.jwtConfig.client.clientLoanProductsEndpoint}/${id}`, { params })
  }

  fetchProcessedLoanRecords(params) {
    return this.axiosIns.get(this.jwtConfig.client.clientProcessedLoanEndpoint, {
      params
    })
  }
}

