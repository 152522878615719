export default {
  // Client Endpoints
  clientDashboardEndpoint: '/client/dashboard',
  requestLoanEndpoint: '/client/request-loan',
  clientProductsEndpoint: '/client/products',
  clientLoanEndpoint: '/client/loans',
  clientLoanClientGroupEndpoint: '/client/client-group',
  clientLoanRepaymentEndpoint: '/client/repayments',
  clientProcessedLoanEndpoint: '/client/processed-loans',
  clientLoanStatement: '/client/statement',
  clientTransactionsEndpoint: '/client/transactions',
  clientLoanProductsEndpoint: '/client-groups',
  clientReferralCommissionsEndpoint: "/client/referrals/commissions"
}
