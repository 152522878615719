export default [
  {
    path: '/client/home',
    name: 'client-home',
    component: () => import('@/pages/client/home'),
    beforeEnter: (to, _, next) => {
      const last_page_accessed = sessionStorage.getItem('last_page_accessed');
      if (
        last_page_accessed &&
        last_page_accessed !== to.name &&
        !last_page_accessed.includes("auth")
      ) {
        sessionStorage.removeItem("last_page_accessed");
        return next({ path: last_page_accessed });
      }
      return next();
    },
    meta: {
      pageTitle: 'Home',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Dashboard",
      roles: ["client"]
    },
  },
  {
    path: '/client/blog/:slug',
    name: 'client-single-blog',
    component: () => import('@/pages/client/blogs/view'),
    meta: {
      pageTitle: 'Blogs',
      action: "read",
      resource: "ClientViewBlogPostRoute",
      breadcrumb: [
        {
          text: 'Home',
          to: { name: 'client-home' },
        },
        {
          text: 'List',
          to: { name: 'client-blogs' },
        },
        {
          text: 'Details',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "View Blog Details",
      roles: ["client"]
    },
  },
  {
    path: '/client/blogs',
    name: 'client-blogs',
    component: () => import('@/pages/client/blogs/list'),
    meta: {
      pageTitle: 'Blogs',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Blogs',
          active: true,
        }
      ],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Blogs",
      roles: ["client"]
    },
  },
  {
    path: '/client/loans',
    name: 'client-loans',
    component: () => import('@/pages/client/loans/list'),
    meta: {
      pageTitle: 'Loans',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Loans',
          active: true,
        }
      ],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Loans",
      roles: ["client"]
    },
  },
  {
    path: '/client/referral/commissions',
    name: 'referral-commissions',
    component: () => import('@/pages/client/referrals/list'),
    meta: {
      pageTitle: 'Referral Commissions',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Referral Commissions',
          active: true,
        }
      ],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Referral Commissions",
      roles: ["client"]
    },
  },
  {
    path: '/client/referral/commissions/withdrawals',
    name: 'referral-commissions-withdrawals',
    component: () => import('@/pages/client/referrals/withdrawals'),
    meta: {
      pageTitle: 'Referral Commission Withdrawal',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Referral Commission Withdrawal',
          active: true,
        }
      ],
      requireAuth: true,
      showPagination: true,
      logPageName: "Client List Referral Commission Withdrawal",
      roles: ["client"]
    },
  },
  {
    path: '/client/loans/:loan_id',
    name: 'client-loans-single',
    component: () => import('@/pages/client/loans/view'),
    meta: {
      pageTitle: 'Loan Details',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Loans',
          to: { name: 'client-loans' },
        },
        {
          text: 'Loan Details',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client View Loan Details",
      roles: ["client"]
    },
  },
  {
    path: '/client/loan/new/:loan_product_id',
    name: 'client-new-loan',
    component: () => import('@/pages/client/loans/new'),
    meta: {
      pageTitle: 'New Loan Request',
      action: "read",
      resource: "ClientRequestLoan",
      breadcrumb: [
        {
          text: 'Loans',
          to: { name: 'client-loans' },
        },
        {
          text: 'Request Loan',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Request Loan",
      roles: ["client"]
    },
  },
  {
    path: '/client/loan/select-product',
    name: 'client-select-loan-product',
    component: () => import('@/pages/client/loans/new/select-loan-product'),
    meta: {
      pageTitle: 'New Loan Request',
      action: "read",
      resource: "ClientRequestLoan",
      breadcrumb: [
        {
          text: 'Loans',
          to: { name: 'client-loans' },
        },
        {
          text: 'Request Loan',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Request Loan",
      roles: ["client"],
      layout: "full"
    },
  },
  {
    path: '/client/loan/new/overview/:loan_product_id',
    name: 'client-new-loan-overview',
    component: () => import('@/pages/client/loans/new/overview'),
    meta: {
      pageTitle: 'Loan Request Overview',
      action: "read",
      resource: 'ClientRequestLoan',
      breadcrumb: [
        {
          text: 'Loans',
          to: { name: 'client-loans' },
        },
        {
          text: 'Loan Product',
          to: { name: 'client-select-loan-product' }
        },
        {
          text: 'Loan Request Overview',
          active: true
        }

      ],
      requireAuth: true,
      logPageName: "Client Request Loan",
      roles: ["client"],
      layout: "full"
    },
  },
  {
    path: '/client/loan/calculator',
    name: 'client-loan-calculator',
    component: () => import('@/pages/client/loans/calculator'),
    meta: {
      pageTitle: 'Loan Calculator',
      action: "read",
      resource: "ClientRequestLoan",
      breadcrumb: [
        {
          text: 'Loans',
          to: { name: 'client-loans' },
        },
        {
          text: 'Request Loan',
          to: { name: 'client-new-loan' },
        },
        {
          text: 'Loan Calculator',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Loan Calculator",
      roles: ["client"]
    },
  },
  {
    path: '/client/loans/:loan_id/pay-off',
    name: 'client-pay-off-loan',
    component: () => import('@/pages/client/manual-repayments/pay-off.vue'),
    meta: {
      pageTitle: 'Repayment',
      action: "read",
      resource: "ClientPayOffLoanRoute",
      breadcrumb: [
        {
          text: 'Loans',
          to: { name: 'client-loans' },
        },
        {
          text: 'Repayment',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client View Loan Payoff Screen",
      roles: ["client"]
    },
  },
  {
    path: '/client/loans/repayments/manual',
    name: 'client-list-manual-repayments',
    component: () => import('@/pages/client/manual-repayments'),
    meta: {
      pageTitle: 'Self Initiated Repayments',
      action: "read",
      resource: "ClientListManualRepaymentsRoute",
      breadcrumb: [
        {
          text: 'Loans',
          to: { name: 'client-loans' },
        },
        {
          text: 'Self Initiated Repayments',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client View Manual Repayments Screen",
      showPagination: true,
      roles: ["client"]
    },
  },
  {
    path: '/client/loans/repayments/manual/:manual_repayment_id',
    name: 'single-manual-repayment',
    component: () => import('@/pages/client/manual-repayments/view.vue'),
    meta: {
      pageTitle: 'Repayment',
      action: "read",
      resource: "ClientSingleManualRepaymentRoute",
      breadcrumb: [
        {
          text: 'Self Initiated Repayments',
          to: { name: 'client-list-manual-repayments' },
        },
        {
          text: 'Repayment Details',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client View Manual Repayment Details Screen",
      roles: ["client"]
    },
  },
  {
    path: '/client/savings',
    name: 'client-savings',
    component: () => import('@/pages/client/savings'),
    meta: {
      pageTitle: 'Savings',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Savings',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Savings",
      roles: ["client"]
    },
  },

  {
    path: '/client/statements',
    name: 'client-statements',
    component: () => import('@/pages/client/statements/list'),
    meta: {
      pageTitle: 'Statement',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Client Statement',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Statements",
      roles: ["client"]
    },
  },
  {
    path: '/client/statements/:loan_id',
    name: 'client-statements-single',
    component: () => import('@/pages/client/statements/detail'),
    meta: {
      pageTitle: 'Client Statement',
      action: "read",
      resource: "ClientRoute",
      breadcrumb: [
        {
          text: 'Client Statement',
          to: { name: 'client-statements' }
        },
        {
          text: 'Client Statement Detail',
          active: true,
        }
      ],
      requireAuth: true,
      logPageName: "Client Statement Detail",
      roles: ["client"]
    },
  },
]
