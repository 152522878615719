export default class PartnerJwtServices {
  axiosIns = null

  jwtConfig = null

  constructor(axiosIns, jwtConfig) {
    this.axiosIns = axiosIns;
    this.jwtConfig = jwtConfig;
  }

  fetchDashboardData() {
    return this.axiosIns.get(`${this.jwtConfig.partner.partnerDashboardEndpoint}`, {})
  }

  fetchDashboardGraphData(params = {}) {
    return this.axiosIns.get(`${this.jwtConfig.partner.partnerDashboardEndpoint}/graph`, {
      params
    })
  }

  fetchLoans(params) {
    return this.axiosIns.get(this.jwtConfig.partner.partnerLoanEndpoint, {
      params
    })
  }

  fetchClientGroups() {
    return this.axiosIns.get(this.jwtConfig.partner.partnerFetchClientGroupsEndpoint, {})
  }

  exportLoans(params) {
    return this.axiosIns.get(`${this.jwtConfig.partner.partnerLoanEndpoint}/export`, {
      params,
      responseType: "blob"
    });
  }

  fetchUserRepayments(params) {
    return this.axiosIns.get(`${this.jwtConfig.partner.partnerLoanRepaymentEndpoint}`, {
      params
    })
  }

  exportRepayments(params) {
    return this.axiosIns.get(`${this.jwtConfig.partner.partnerLoanRepaymentEndpoint}/export`, {
      params,
      responseType: "blob"
    })
  }

  fetchPartnerAdministrators(params) {
    return this.axiosIns.get(this.jwtConfig.partner.partnerAdminsEndpoint, {
      params
    })
  }

  fetchPartnerAdministrator(partner_admin_id) {
    return this.axiosIns.get(`${this.jwtConfig.partner.partnerAdminsEndpoint}/${partner_admin_id}`, {})
  }

  updatePartnerAdministrator(partner_admin_id, payload) {
    return this.axiosIns.put(`${this.jwtConfig.partner.partnerAdminsEndpoint}/${partner_admin_id}`, payload)
  }

  deletePartnerAdministrator(partner_admin_id) {
    return this.axiosIns.delete(`${this.jwtConfig.partner.partnerAdminsEndpoint}/${partner_admin_id}`, {})
  }

  createPartnerAdministrator(payload) {
    return this.axiosIns.post(this.jwtConfig.partner.partnerAdminsEndpoint, payload)
  }

  resendPartnerAdminInvitation(payload) {
    return this.axiosIns.post(`${this.jwtConfig.partner.partnerAdminsEndpoint}/resend-invitation`, payload)
  }
}

