export default [
  {
    path: '/auth/login',
    name: 'auth-login',
    component: () => import('@/pages/auth/login'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["client"]
    },
  },
  {
    path: '/auth/register',
    name: 'auth-register',
    component: () => import('@/pages/auth/register'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["client"]
    },
  },
  {
    path: '/auth/register/closed',
    name: 'auth-no-signups',
    component: () => import('@/pages/auth/no-signups'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["client"],
      redirectIfLoggedIn: true
    },
  },
  {
    path: '/auth/register/success',
    name: 'auth-register-success',
    component: () => import('@/pages/auth/register-success'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["client"]
    },
  },
  {
    path: '/auth/mfa',
    name: 'auth-mfa',
    component: () => import('@/pages/auth/mfa'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["client"]
    },
  },
  {
    path: '/auth/password-expiration',
    name: 'auth-password-expiration',
    component: () => import('@/pages/auth/password-expiration'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      redirectIfLoggedIn: true,
      roles: ["client"]
    },
  },
  {
    path: '/auth/register/confirm-email',
    name: 'auth-register-confirm-email',
    component: () => import('@/pages/auth/confirm-email'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["client"]
    }
  },
  {
    path: '/auth/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/pages/auth/forgot-password'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["client"]
    },
  },
  {
    path: '/auth/forgot-password-mfa',
    name: 'auth-forgot-password-mfa',
    component: () => import('@/pages/auth/forgot-password/mfa-password-reset'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      redirectIfLoggedIn: true,
      roles: ["client"]
    },
  },
  {
    path: '/auth/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/pages/auth/reset-password'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      redirectIfLoggedIn: true,
      roles: ["client"]
    },
  },
  {
    path: '/auth/verify-phone',
    name: 'auth-verify-phone',
    component: () => import('@/pages/auth/verify-phone'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      logPageName: "View Verify Phone Page",
      roles: ["client"]
    },
  },
  {
    path: '/auth/onboarding/stage-1',
    name: 'onboarding-stage-1',
    component: () => import('@/pages/auth/onboarding/card-details'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      logPageName: "Provide ID Card",
      roles: ["client"]
    },
  },
  {
    path: '/auth/onboarding/stage-2',
    name: 'onboarding-stage-2',
    component: () => import('@/pages/auth/onboarding/residential'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      logPageName: "Provide ID Card",
      roles: ["client"]
    },
  },
  {
    path: '/auth/account',
    name: 'auth-update-profile-account',
    component: () => import('@/pages/auth/profile'),
    meta: {
      action: 'read',
      resource: 'AuthRoute',
      requireAuth: true,
      logPageName: "View Profile Page",
      roles: ["client"]
    },
  },
  {
    path: '/auth/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/pages/auth/error-403'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: "read",
      roles: ["client"]
    },
  },
  {
    path: '/auth/maintenance-mode',
    name: 'misc-under-maintenance',
    component: () => import('@/pages/auth/maintenance'),
    meta: {
      layout: 'full',
      action: "read",
      resource: 'AuthRoute',
      roles: ["client"],
      redirectIfLoggedIn: true
    },
  },

  //  Security routes
  {
    path: '/security/mfa',
    name: 'security-mfa',
    component: () => import('@/pages/auth/security/mfa'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: ["client"]
    }
  },
  {
      path: '/security/mfa-authentication',
      name: 'security-mfa-authentication',
      component: () => import('@/pages/auth/security/authentication'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        redirectIfLoggedIn: true,
        roles: ["client"]
      },
    },
  {
      path: '/security/authentication-app',
      name: 'security-authentication-app',
      component: () => import('@/pages/auth/security/authentication-app'),
      meta: {
        layout: 'full',
        resource: 'AuthRoute',
        action: 'read',
        roles: ["client"]
      },
    },
  {
    path: '/security/mfa-activation',
    name: 'security-mfa-activation',
    component: () => import('@/pages/auth/security/activation'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["client"]
    },
  },
  {
    path: '/security/activation-app',
    name: 'security-activation-app',
    component: () => import('@/pages/auth/security/activation-app'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["client"]
    },
  },
  {
    path: '/security/mfa-recovery-codes',
    name: 'security-mfa-recovery-codes',
    component: () => import('@/pages/auth/security/recovery-codes'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      // requireAuth: true,
      roles: ["client"]
    },
  },
  {
    path: '/security/mfa-recovery',
    name: 'security-mfa-recovery',
    component: () => import('@/pages/auth/security/mfa-recovery'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      // requireAuth: true,
      roles: ["admin", "anonymous"]
    },
  },
  {
    path: '/security/mfa-recovery-success',
    name: 'security-mfa-recovery-success',
    component: () => import('@/pages/auth/security/success'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      // requireAuth: true,
      roles: ["admin", "anonymous"]
    },
  },
  {
    path: '/auth/register/welcome',
    name: 'auth-register-welcome',
    component: () => import('@/pages/auth/register-welcome'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      roles: ["client"]
    },
  },
  {
    path: '/security/password/change',
    name: 'security-password-change',
    component: () => import('@/pages/auth/change-password'),
    meta: {
      layout: 'full',
      resource: 'AuthRoute',
      action: 'read',
      requireAuth: true,
      roles: ["client"]
    },
  }
]
